.bg {
  position: absolute;
  top: 0;
  left: 0;
}
.blob-one {
  border-radius: 30% 70% 70% 30% / 39% 28% 72% 61%;
  height: 10rem;
  width: 10rem;
  background-color: rgb(153, 51, 127);
  background: linear-gradient(to right, rebeccapurple, rgb(153, 51, 127));
}

html {
  height: 100%;
}

body {
  margin: 0;
}
.bgs {
  display: flex;
  justify-content: center;
  position: relative;
}
.bg {
  animation: slide 3s ease-in-out infinite alternate;
  background: rgba(187, 189, 186, 0.253) 50%;
  bottom: 0;
  /* left: -50%; */
  opacity: 0.5;
  position: fixed;
  /* right: -50%; */
  top: 0;
  z-index: -1;
}

.bg2 {
  animation-direction: alternate;
  animation-duration: 10s;
  margin-top: -25vh;
  height: 80vh;
  width: 50vw;
  border-radius: 67% 33% 61% 39% / 39% 43% 57% 61%;
}

.bg3 {
  animation-duration: 5s;
  margin-top: 60vh;
  height: 40vh;
  width: 20vw;
  border-radius: 67% 33% 61% 39% / 39% 59% 41% 61%;
}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(95%);
  }
}

@media screen and (width < 769px) {
  .bg2 {
    height: 50vh;
    width: 80vw;
  }

  .bg3 {
    height: 20vh;
    width: 20vw;
  }
}
